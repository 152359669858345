<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 573.92 522.73"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M542,48.23C514.56,15.27,478.66-.17,436.17.09,399.78.32,367.87,13.32,340.3,37c-5.52,4.73-10.56,10-15.82,15a3.58,3.58,0,0,1-.92-.45c-1.08-1.05-2.16-2.09-3.16-3.2C304.68,30.92,285.57,18.59,263.85,10,213.06-10.06,151.91-.15,112.75,41.53,83.2,73,71.63,110.57,75.85,153.09c2.88,29.07,14.3,55.27,29.3,80,16.95,28,38.32,52.4,60.61,76.1,37.74,40.11,78.94,76.56,119.83,113.33C298.33,434,311.26,445.26,324.52,457c4-3.63,7.61-6.82,11.11-10.13,47.16-44.47,94.69-88.55,141.27-133.61,22.5-21.77,43.06-45.51,60.57-71.67,16.55-24.74,29.64-51,34.37-80.7C578.49,119,569.39,81,542,48.23Zm-32.78,147.4c-12.58,24-29.82,44.7-48.45,64-25.71,26.58-52.53,52.1-79.23,77.7-18.28,17.53-37.18,34.42-55.81,51.58a10.06,10.06,0,0,1-1.4.91C278.63,349.52,234,308.72,192.42,264.65c-19.31-20.5-38-41.69-51.28-66.84-11.23-21.22-18.08-43.46-16.83-68,1.89-37.18,30.62-70.61,67.37-77.42,41.92-7.78,81.55,9.62,104.4,45.83a5,5,0,0,1,.76,1.28c2.41,10.32,9.64,11.5,18.76,10.85,9.79-.7,19.66-.23,29.49-.11,2.6,0,4.24-.45,5.27-3.18,17.71-47.17,83.36-72.56,132.24-46.36,26.11,14,41.28,36.51,44,66.7C528.91,152.28,520.42,174.29,509.25,195.63Z"/><path d="M118.93,330.62c-27.16-30.16-52.79-61.43-71-98-10.1-20.23-17.6-41.42-20.53-63.9-1.88-14.5-2.6-29.16-3.86-43.93C4.67,153.35-2.72,184.94.88,219.05,4,248.11,15.25,274.28,30.29,299c18.22,30,41,56.35,66.09,80.58,41.55,40.18,84,79.4,126.2,118.94,8.72,8.18,17.78,16,26.93,24.19l37.28-32.84c-18-16.28-35.58-32-53-48.07C194.64,405.67,154.68,370.32,118.93,330.62Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconHeart',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
